import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Overview`}</h2>
    <img width="960" alt="Comparative display of GitHub interface elements in light and dark modes. The light mode shows a browser window with a light background, green contribution graph, and standard browser controls. The dark mode presents the same design with a dark background, enhancing the visibility of green contribution graph and browser controls." src="https://github.com/primer/primitives/assets/18661030/615e3e06-9289-4adc-ab85-2213fae96e8c" style={{
      "marginBottom": "1rem"
    }} />
    <p>{`GitHub's UI offers two color modes, `}<inlineCode parentName="p">{`light`}</inlineCode>{` and `}<inlineCode parentName="p">{`dark`}</inlineCode>{`. Primer supports both color modes across nine `}<a parentName="p" {...{
        "href": "../../foundations/primitives/getting-started#theming"
      }}>{`themes`}</a>{`. Every pattern in Primer is built to work across all color modes out of the box.`}</p>
    <h3>{`Color modes in Figma`}</h3>
    <p>{`When designing product interfaces in Figma, we recommend using `}<strong parentName="p">{`light mode`}</strong>{` or `}<strong parentName="p">{`dark mode`}</strong>{` as the Primer Web Figma library provides components and tokens in those two modes.
You can learn more about using design tokens in Figma in our `}<a parentName="p" {...{
        "href": "../../guides/figma/getting-started#styles-and-variables"
      }}>{`Figma guidelines`}</a>{`.`}</p>
    <h3>{`Color design tokens`}</h3>
    <p>{`Primer delivers colors in the form of `}<a parentName="p" {...{
        "href": "../../foundations/primitives/getting-started"
      }}>{`design tokens`}</a>{`. Design tokens are a layer of abstraction that allows for better maintainability, consistency and theming. Within Primer, design tokens are represented as CSS variables for code and Figma variables for design. For a full reference table of all available design tokens, see the `}<a parentName="p" {...{
        "href": "../../foundations/primitives/color"
      }}>{`color CSS variables`}</a>{` page.`}</p>
    <p>{`As an example of how color tokens work, when `}<inlineCode parentName="p">{`bgColor-default`}</inlineCode>{` is referenced for a background color, the value of that token will automatically change depending on the color mode.`}</p>
    <img width="960" alt="GitHub Wiki page theming comparison. On the left, a light theme featuring a card with a welcome message, a green 'Create the first page' button, and annotated with 'fgColor-default' for foreground and 'bgColor-default' for background colors. On the right, the same card is shown in dark mode with the text and button colors adjusted for contrast, also annotated with color roles." src="https://github.com/primer/react/assets/18661030/21726ed0-2175-44a3-a821-af002778c0b3" />
    <h3>{`Design token categories`}</h3>
    <p>{`Primer design tokens are categorized into three groups:`}</p>
    <ul>
      <li parentName="ul">{`Base`}</li>
      <li parentName="ul">{`Functional`}</li>
      <li parentName="ul">{`Component/pattern`}</li>
    </ul>
    <p>{`To read more about the naming convention, see the `}<a parentName="p" {...{
        "href": "../../foundations/primitives/token-names"
      }}>{`design token naming guidelines`}</a>{` page.`}</p>
    <img width="960" alt="Color design guide for GitHub interface elements showing a transition from a base color, a magenta (#bf3989) to a functional color name 'color-scale-pink-5', and then to 'borderColor-sponsors-emphasis'. Below the base color, a color scale from 0 to 9 shows varying shades of pink. Two button examples demonstrate the practical application of the colors: one in a light theme with 'Sponsor' text and heart icon and another in a dark theme with the same elements." src="https://github.com/primer/primitives/assets/18661030/10dd4c91-5faa-464c-ab07-9e26afd54e5f" style={{
      "marginBottom": "1rem"
    }} />
    <p><strong parentName="p">{`Base`}</strong>{` color tokens are the lowest level tokens and map directly to a raw value. They are `}<strong>{`only`}</strong>{` to be used as a reference for functional and component/pattern tokens. Base color tokens don't respect color modes and should never be used directly in code or design.`}</p>
    <p>{`Example: `}<inlineCode parentName="p">{`color-scale-pink-5`}</inlineCode></p>
    <p><strong parentName="p">{`Functional`}</strong>{` color tokens represent global UI patterns such as text, borders, shadows, and backgrounds. These are the most commonly used design tokens throughout all of Primer and GitHub UI. Functional color tokens reference base color tokens under the hood, and respect color modes.`}</p>
    <p>{`Example: `}<inlineCode parentName="p">{`borderColor-sponsors-emphasis`}</inlineCode></p>
    <img width="960" alt="GitHub interface component patterns displaying color coding for UI elements. On the left, a red border box indicates 'borderColor-danger-emphasis' changing to 'control-borderColor-danger'. On the right, a blue border box signifies 'borderColor-accent-emphasis' transitioning to 'focus-outlineColor'. Examples include text input fields for an email address with error messages and toggle switches in both light and dark themes." src="https://github.com/primer/primitives/assets/18661030/4b91c346-24c4-4926-911d-de9d27c851d3" style={{
      "marginBottom": "1rem",
      "marginTop": "1rem"
    }} />
    <p><strong parentName="p">{`Component/pattern`}</strong>{` tokens are used for values that are more specific or unique than functional tokens. These tokens are limited and functional tokens are preferred. Component/pattern color tokens may reference both base and functional tokens under the hood, and respect color modes.`}</p>
    <p>{`Example: `}<inlineCode parentName="p">{`focus-outlineColor`}</inlineCode></p>
    <h2>{`Neutral colors`}</h2>
    <p>{`Shades of gray used for text, borders, backgrounds, and shadows.`}</p>
    <h3>{`Foreground`}</h3>
    <p>{`Foreground tokens use the `}<inlineCode parentName="p">{`fgColor`}</inlineCode>{` property and are used for text and icons.`}</p>
    <img width="960" alt="GitHub pull request interface demonstrating foreground color roles in light and dark themes. Both themes highlight text 'Guidelines #798' in 'fgColor-default', the 'Draft' label in 'fgColor-onEmphasis', and a clickable username in 'fgColor-link'. The light theme uses 'fgColor-muted' for auxiliary text, mirrored in the dark theme for contrast optimization." src="https://github.com/primer/react/assets/18661030/b8f72aab-98eb-4b7a-a5fa-fd8d63e9b8cd" />
    <h3>{`Background`}</h3>
    <p>{`Background tokens use the `}<inlineCode parentName="p">{`bgColor`}</inlineCode>{` property and are used for backgrounds and fills.`}</p>
    <img width="960" alt="GitHub's navigation menu in light and dark themes, annotated to indicate background color roles. In light mode, the menu has 'bgColor-inset' for the avatar circle, 'bgColor-emphasis' for the 'Pull requests' button, 'overlay-bgColor' for hover effects, 'bgColor-muted' for inactive menu items, and 'bgColor-default' for the overall background. The dark mode mirrors this scheme with appropriate colors for visibility." src="https://github.com/primer/react/assets/18661030/477efd99-16a0-42f7-8bd4-68c05bb5376a" />
    <h3>{`Border`}</h3>
    <p>{`Border tokens use the `}<inlineCode parentName="p">{`borderColor`}</inlineCode>{` property and are used for borders and dividers.`}</p>
    <img width="960" alt="GitHub interface dropdown menu with border color annotations in light and dark themes. In light mode, the dropdown is outlined with 'borderColor-default', while menu options are separated by 'borderColor-muted'. In dark mode, the same color roles apply with adjusted shades for contrast." src="https://github.com/primer/react/assets/18661030/6e916437-0bcd-4dd0-9e88-f014f27d1fd0" />
    <h3>{`Shadow`}</h3>
    <p>{`Shadow tokens use the `}<inlineCode parentName="p">{`shadow`}</inlineCode>{` property and are used for shadows and elevation.`}</p>
    <img width="960" alt="GitHub interface design elements annotated with shadow styles: 'shadow-floating-small', 'shadow-resting-xsmall', and 'shadow-inset', displayed on light and dark mode backgrounds." src="https://github.com/primer/primitives/assets/18661030/246b3dc1-42b4-4125-aaf1-f936bc29c995" />
    <h2>{`Semantic colors`}</h2>
    <p>{`Semantic colors are typically used to communicate status, action, or emphasis. Each semantic color is tied to a `}<a parentName="p" {...{
        "href": "#color-roles"
      }}>{`role`}</a>{` with a specific meaning. Color tokens are available for foreground, background, and border. Background and border colors have both a `}<inlineCode parentName="p">{`muted`}</inlineCode>{` and `}<inlineCode parentName="p">{`emphasis`}</inlineCode>{` option.`}</p>
    <img width="960" alt="Overview of GitHub UI color roles in light and dark themes. Each theme displays labeled color swatches for: 'accent-emphasis' in blue, 'accent-muted' in light blue, 'success-emphasis' in green, 'success-muted' in light green, 'danger-emphasis' in red, 'danger-muted' in light red, 'warning-emphasis' in yellow, 'warning-muted' in light yellow, 'done-emphasis' in purple, 'done-muted' in light purple, 'sponsor-emphasis' in magenta, and 'sponsor-muted' in light magenta." src="https://github.com/primer/primitives/assets/18661030/165ceeae-9933-473a-976f-655c918be1c2" />
    <h3>{`Muted`}</h3>
    <p>{`Muted background and border colors are often combined to draw attention to a specific piece of content with a subtle emphasis.`}</p>
    <img width="960" alt="GitHub interface elements showing a warning message and a user comment in both light and dark modes. The warning message has a yellow background with a caution icon and is dismissible with a close button. The user comment by 'monalisa' features a speech bubble with a light blue background in light mode and a dark blue background in dark mode, both with muted borders." src="https://github.com/primer/react/assets/18661030/df33b03c-ef06-4dc6-9d85-f6dd9cd3f3f0" />
    <h3>{`Emphasis`}</h3>
    <p>{`Emphasis background colors provide a stronger emphasis for UI elements and are always combined with `}<inlineCode parentName="p">{`fgColor-onEmphasis`}</inlineCode>{` tokens for text and icons.`}</p>
    <img width="960" alt="GitHub interface showcasing button styles with emphasis colors in light and dark modes. The 'Primary' button is green, the 'Danger' button is red with a trash icon, the 'Queued' button is brown with a clock icon, and the 'Beta' button is outlined in green. Annotations indicate 'borderColor-{role}-emphasis' and 'bgColor-{role}-emphasis' for each button style." src="https://github.com/primer/react/assets/18661030/3e4ec1f8-cbc5-4ebf-9816-7f247474b9c6" />
    <h3>{`Semantic foreground`}</h3>
    <p>{`Foreground semantic colors provide contrast against `}<inlineCode parentName="p">{`muted`}</inlineCode>{` and default background colors and should be used for text and icons.`}</p>
    <img width="960" alt="GitHub interface elements displaying in light and dark themes with foreground color roles. Tags include 'Sponsor' with a heart icon, 'Verified' with a checkmark, and language tags 'css' and 'ruby'. The colors of text and icons are adjusted according to the theme for optimal contrast, annotated with 'fgColor-{role}'." src="https://github.com/primer/react/assets/18661030/7fcd6efb-ec86-4460-98aa-e3e5be062bec" />
    <h2>{`Color roles`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Color roles`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Box sx={{
              display: 'inline-flex',
              alignContent: 'center',
              gap: '4px',
              verticalAlign: 'middle'
            }} mdxType="Box"><Box sx={{
                backgroundColor: 'accent.emphasis',
                height: '24px',
                width: '24px',
                borderRadius: 1
              }} mdxType="Box"></Box>{` `}<inlineCode parentName="td">{`accent`}</inlineCode></Box></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Links, selected, active, and focus states, and neutral information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Box sx={{
              display: 'inline-flex',
              alignContent: 'center',
              gap: '4px',
              verticalAlign: 'middle'
            }} mdxType="Box"><Box sx={{
                backgroundColor: 'success.emphasis',
                height: '24px',
                width: '24px',
                borderRadius: 1
              }} mdxType="Box"></Box>{` `}<inlineCode parentName="td">{`success`}</inlineCode></Box></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Primary buttons, positive messaging and successful states`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Box sx={{
              display: 'inline-flex',
              alignContent: 'center',
              gap: '4px',
              verticalAlign: 'middle'
            }} mdxType="Box"><Box sx={{
                backgroundColor: 'attention.emphasis',
                height: '24px',
                width: '24px',
                borderRadius: 1
              }} mdxType="Box"></Box>{` `}<inlineCode parentName="td">{`attention`}</inlineCode></Box></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Warning states, active processes such as queued PRs and tests in progress`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Box sx={{
              display: 'inline-flex',
              alignContent: 'center',
              gap: '4px',
              verticalAlign: 'middle'
            }} mdxType="Box"><Box sx={{
                backgroundColor: 'danger.emphasis',
                height: '24px',
                width: '24px',
                borderRadius: 1
              }} mdxType="Box"></Box>{` `}<inlineCode parentName="td">{`danger`}</inlineCode></Box></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Danger buttons and error states`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Box sx={{
              display: 'inline-flex',
              alignContent: 'center',
              gap: '4px',
              verticalAlign: 'middle'
            }} mdxType="Box"><Box sx={{
                backgroundColor: 'open.emphasis',
                height: '24px',
                width: '24px',
                borderRadius: 1
              }} mdxType="Box"></Box>{` `}<inlineCode parentName="td">{`open`}</inlineCode></Box></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Open tasks, PRs or workflows`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Box sx={{
              display: 'inline-flex',
              alignContent: 'center',
              gap: '4px',
              verticalAlign: 'middle'
            }} mdxType="Box"><Box sx={{
                backgroundColor: 'closed.emphasis',
                height: '24px',
                width: '24px',
                borderRadius: 1
              }} mdxType="Box"></Box>{` `}<inlineCode parentName="td">{`closed`}</inlineCode></Box></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Closed tasks, PRs or workflows`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Box sx={{
              display: 'inline-flex',
              alignContent: 'center',
              gap: '4px',
              verticalAlign: 'middle'
            }} mdxType="Box"><Box sx={{
                backgroundColor: 'done.emphasis',
                height: '24px',
                width: '24px',
                borderRadius: 1
              }} mdxType="Box"></Box>{` `}<inlineCode parentName="td">{`done`}</inlineCode></Box></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Completed tasks, PRs or workflows`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><Box sx={{
              display: 'inline-flex',
              alignContent: 'center',
              gap: '4px',
              verticalAlign: 'middle'
            }} mdxType="Box"><Box sx={{
                backgroundColor: 'sponsors.emphasis',
                height: '24px',
                width: '24px',
                borderRadius: 1
              }} mdxType="Box"></Box>{` `}<inlineCode parentName="td">{`sponsors`}</inlineCode></Box></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text and icons related to GitHub Sponsors`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Developers`}</h2>
    <p>{`Colors are available in the form of CSS variables and CSS utility classes that can be used across all frameworks including React and Rails. Check out the references below for more information.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../../foundations/primitives/color"
        }}>{`Color CSS variables`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../../foundations/css-utilities/colors"
        }}>{`Color CSS utility classes`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      